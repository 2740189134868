import React, { Component, Fragment, useEffect, useState } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import * as analytics from '@/shared/utils/analytics'
import { showToast } from '@/shared/utils/toast'
import { handleError } from '@/shared/api'
import { fetchProperties, pauseListing, deleteProperty, cloneProperty } from '@/hostProperties/api'
import {
  DashboardMenu, PageSpinner, Button, Icon, EmptyState,
  Head, Input, Select
} from '@/shared/components'
import AppHeader from '@/features/appHeader/components'
import AppFooter from '@/features/appFooter/components'
import PropertyRow from './PropertyRow'
import Slug from './Slug'
import '@/hostProperties/styles/properties.scss'

const c = 'hostProperties'

const Properties = () => {
  let fetchCount = 0

  const getInitialQuery = () => ({
    query: null,
    paused: false,
  })

  const [state, setState] = useState(() => ({
    properties: [],
    isFetching: true,
    query: getInitialQuery(),
  }))

  useEffect(() => {
    analytics.pageview('Host Properties')
    fetchHostProperties()
  }, [])

  const fetchHostProperties = (query = {}) => {
    fetchCount += 1
    query = {
      ...state.query,
      ...query,
    }
    setState(prevState => ({
      ...prevState,
      query,
      isFetching: true,
    }))
    fetchProperties(query).then(({ data }) => {
      setState(prevState => ({
        ...prevState,
        properties: data,
        isFetching: false,
      }))
    }, handleError)
  }

  const handlePropertyPause = ({ id, paused }, confirmModal) => {
    pauseListing(id, { paused: !paused }).then(() => {
      const properties = _.cloneDeep(state.properties)
      const property = properties.find(p => p.listing.id === id)
      property.listing.paused = !paused
      setState(prevState => ({
        ...prevState,
        properties: properties
      }))
      confirmModal.close()
    }, handleError)
  }

  const handlePropertyDelete = id => {
    deleteProperty(id).then(() => {
      const updatedProperties = state.properties.filter(p => p.id !== id)
      setState(prevState => ({
        ...prevState,
        properties: updatedProperties,
      }))
      showToast({ title: 'Your property was deleted successfully.' })
    }, handleError)
  }

  const handlePropertyClone = (id, confirmModal) => {
    cloneProperty(id).then(({ data }) => {
      window.scrollTo(0, 150)
      const updatedProperties = [...state.properties, data]
      setState(prevState => ({
        ...prevState,
        properties: updatedProperties,
      }))
      confirmModal.close()
      showToast({ title: 'Your property was duplicated successfully.' })
    }, handleError)
  }

  const hasActiveFilters = !_.isEqual(state.query, getInitialQuery())

  return (
    <div className={c}>
      <Head title="Properties - Roomsie" />
      <AppHeader />
      <DashboardMenu role="host" />
      <Page
        {...state}
        hasActiveFilters={hasActiveFilters}
        fetchCount={fetchCount}
        fetchHostProperties={fetchHostProperties}
        handlePropertyPause={handlePropertyPause}
        handlePropertyDelete={handlePropertyDelete}
        handlePropertyClone={handlePropertyClone}
      />
      <AppFooter />
    </div>
  )
}

const Page = ({ query, properties, isFetching, fetchHostProperties, hasActiveFilters, fetchCount, handlePropertyPause, handlePropertyDelete, handlePropertyClone }) => {
  if (properties.length || hasActiveFilters || fetchCount > 1) {
    return (
      <PageContent
        query={query}
        properties={properties}
        isFetching={isFetching}
        fetchHostProperties={fetchHostProperties}
        handlePropertyPause={handlePropertyPause}
        handlePropertyDelete={handlePropertyDelete}
        handlePropertyClone={handlePropertyClone}
      />
    )
  }
  if (isFetching) {
    return <PageSpinner />
  }
  return (
    <EmptyState
      title="You have not listed any properties yet."
      message="After listing a property you will be able to manage it right here. Don't worry, it's pretty easy to get started."
      buttonText="List your space"
      buttonLink="/list-space"
    />
  )
}

const Top = () => (
  <div className={`${c}_top`}>
    <h1>My Properties</h1>
    <Slug renderLink={() => (
      <Button hollow size="small">Share All <span>Properties</span></Button>
    )} />
  </div>
)

const Filters = ({ query, fetchHostProperties }) => (
  <div className={`${c}_filters_container`}>
    <div className={`${c}_filters`}>
      <Select
        size="small"
        value={query.paused === null ? '' : query.paused}
        onChange={(e, paused) => fetchHostProperties({ paused: paused || null })}
        options={[
          { label: 'Show All', value: '' },
          { label: 'Show Active', value: false },
          { label: 'Show Paused', value: true },
        ]}
      />
      <Input
        size="small"
        icon="search"
        placeholder="Search by address"
        onChange={_.debounce((e, query) => fetchHostProperties({ query }), 700)}
      />
    </div>
  </div>
)

const PageContent = ({
  query,
  properties,
  isFetching,
  fetchHostProperties,
  handlePropertyPause,
  handlePropertyDelete,
  handlePropertyClone
}) => (
  <Fragment>
    <Top />
    <Filters query={query} fetchHostProperties={fetchHostProperties} />
    <div className={classnames(`${c}_list`, {
      [`${c}_list--isFetching`]: isFetching,
    })}>
      {!properties.length && isFetching && <PageSpinner />}
      {!properties.length && !isFetching && (
        <div className={`${c}_empty`}>
          <div>We could not find any properties.</div>
        </div>
      )}
      {!!properties.length && (
        properties.sort((a, b) => b.id - a.id).map(property => (
          <PropertyRow
            key={property.id}
            property={property}
            onPause={confirmModal => handlePropertyPause(property.listing, confirmModal)}
            onDelete={() => handlePropertyDelete(property.id)}
            onClone={confirmModal => handlePropertyClone(property.id, confirmModal)}
          />
        ))
      )}
    </div>
  </Fragment>
)

export default Properties
