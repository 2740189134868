import _ from 'lodash'

export const parseGooglePlace = place => {
  const get = (type, len = 'long') => (
    (place.address_components.find(o => o.types.includes(type)) || {})[`${len}_name`] || ''
  )
  const ret = {
    id: place.id,
    center: place.geometry.location.toJSON(),
    bounds: place.geometry.viewport.toJSON(),
    formattedAddress: place.formatted_address,
    types: place.types,
    floor: get('floor'),
    streetNumber: get('street_number'),
    street: get('route'),
    streetWithNumber: `${get('street_number')} ${get('route')}`.trim(),
    neighborhood: get('neighborhood'),
    city: get('locality'),
    county: get('administrative_area_level_2'),
    state: get('administrative_area_level_1'),
    stateCode: get('administrative_area_level_1', 'short'),
    postalCode: get('postal_code'),
  }
  ret.name = place.name
  if (ret.types.includes('locality')) {
    ret.name = [ret.name, ret.stateCode].filter(part => part).join(', ')
  }
  ret.suggestedZoom = 15
  if (ret.types.includes('administrative_area_level_1')) {
    ret.suggestedZoom = 8
  } else if (ret.types.includes('administrative_area_level_2')) {
    ret.suggestedZoom = 10
  } else if (ret.types.includes('locality')) {
    ret.suggestedZoom = 12
  } else if (ret.types.includes('neighborhood')) {
    ret.suggestedZoom = 15
  }
  return ret
}

let geocoder = null
export const geocode = (google, options, {
  success = () => { },
  error = () => { },
}) => {
  if (!geocoder) {
    geocoder = new google.maps.Geocoder()
  }
  geocoder.geocode(options, (results, status) => {
    if (status === 'OK' && results[0]) {
      success(parseGooglePlace(results[0]))
    } else {
      error(results, status)
    }
  })
}

export const boundsArrayToObject = arr => {
  const [west, south, east, north] = arr
  return { south, west, north, east }
}

export const pointToLatLng = (google, map, point) => {
  const projection = map.getProjection()
  const topRight = projection.fromLatLngToPoint(map.getBounds().getNorthEast())
  const bottomLeft = projection.fromLatLngToPoint(map.getBounds().getSouthWest())
  const scale = Math.pow(2, map.getZoom())
  const worldPoint = new google.maps.Point(
    point.x / scale + bottomLeft.x,
    point.y / scale + topRight.y
  )
  return map.getProjection().fromPointToLatLng(worldPoint)
}

export const latLngToPoint = (google, map, latLng) => {
  const projection = map.getProjection()
  const topRight = projection.fromLatLngToPoint(map.getBounds().getNorthEast())
  const bottomLeft = projection.fromLatLngToPoint(map.getBounds().getSouthWest())
  const scale = Math.pow(2, map.getZoom())
  const worldPoint = projection.fromLatLngToPoint(latLng)
  return new google.maps.Point(
    (worldPoint.x - bottomLeft.x) * scale,
    (worldPoint.y - topRight.y) * scale
  )
}

export const formatAddress = (addressData, omittedParts) => (
  _.values(
    _.omit(
      _.pick(addressData, [
        'streetAddress',
        'streetAddress2',
        'city',
        'state',
        'zip',
        'neighborhood',
      ]),
      omittedParts
    )
  ).filter(p => p).join(', ')
)

export const formatShortAddress = (addressData, omittedParts) => (
  _.values(
    _.omit(
      _.pick(addressData, [
        'streetAddress',
        'state',
      ]),
      omittedParts
    )
  ).filter(p => p).join(', ')
)